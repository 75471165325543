import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Seo from '../components/seo';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import DefaultLangSelector from '../components/LangSelector';
import { APARTMENT_SELECTION } from '../routes';
import bgShadowSrc from '../images/bg-shadow.jpg';

const LangSelector = styled(DefaultLangSelector)`

  @media (min-width: 768px) {
    top: 20px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  @media (min-width: 992px) {
    top: 40px;
    right: 26px;
    left: auto;
    transform: none;
  }

  @media (min-width: 1200px) {
    right: 56px;
  }
`;

const Content = styled.div`
`;

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  /* padding-left: 30px; */
`;

const Section = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  padding: 70px 1.5rem;

  @media(min-width: 992px) {
    padding: 90px 40px 40px;
  }

  @media(min-width: 1200px) {
    padding: 40px 70px;
  }
`;

const Sidebar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* justify-content: space-between; */
  /* position: sticky; */
  /* top: 0; */
  background-color: #f1f2f2;
  padding: 2rem 1.5rem;
  column-gap: 1rem;
  /* height: calc(100vh - 132px); */

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 576px) {
    grid-template-columns: 1fr 2fr;
  }

  @media(min-width: 992px) {
    /* padding: 40px 50px; */
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 1200px) {
    display: block;
    height: 100%;
    grid-template-columns: 1fr;
  }

  @media(min-width: 1400px) {
    padding: 40px 50px;
  }
`;

const ButtonsWrapper = styled.div`

  @media(min-width: 992px) {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media(min-width: 1200px) {
    display: block;
  }
`;

const Title = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 3rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }

  @media(min-width: 1200px) {
    margin-bottom: 90px;
  }
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
  text-align: center;
  color: #303030;
  margin-bottom: 2rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;

  /* @media(min-width: 992px) {
    grid-template-columns: 400px 1fr;
    min-height: calc(100vh - 132px);
  } */

  @media(min-width: 1200px) {
    grid-template-columns: 280px 1fr;
    min-height: calc(100vh - 132px);
  }

  @media(min-width: 1400px) {
    grid-template-columns: 350px 1fr;
    min-height: calc(100vh - 132px);
  }

  @media(min-width: 1600px) {
    grid-template-columns: 400px 1fr;
  }

`;

const Btn = styled.span`
  display: block;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.875rem;
  color:  ${({ isActive }) => isActive ? '#fff' : '#ff940a'};
  border: 2px solid #ff940a;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ isActive }) => isActive ? '#ff940a' : 'transparent'};
  cursor: pointer;

  &:hover {
    background-color: #ff940a;
    color: #fff;
  }

  @media(min-width: 1200px) {
    font-size: 1.125rem;
    padding: 0.875rem;
  }
`;

const DarkBtn = styled(Link)`
  display: block;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: #303030;
  padding: 0.5rem 0.875rem;
  border: 2px solid #303030;
  text-decoration: none;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #303030;
    color: #fff;
    text-decoration: none;
  }

  @media(min-width: 1200px) {
    font-size: 1.125rem;
    padding: 0.875rem;
  }
`;

const Img = styled.img`
  display: none;
  width: 100%;
  height: auto;
  margin-top: 60px;
  margin-bottom: 32px;

  @media(min-width: 1200px) {
    display: block;
  }
`;

const TitleWrapper = styled.div`
  @media(min-width: 992px) {
    ${Img} {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media(min-width: 1200px) {
    ${Img} {
      display: none;
    }
  }
`;

const Path = styled.path`
   fill: ${({fillColor}) => fillColor};
`;

const Picker = styled.div`
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  a {
    text-decoration: none;
    z-index: 2;
  }

  a:hover {
    text-decoration: none;
    ${Path} {
      opacity: 0.75;
    }
  }


  ${Path} {
    opacity: 0.5;

    /* fill: #eaa548; */
    cursor: pointer;
    transition: all .5s ease;
    mix-blend-mode: multiply;
    isolation: isolate;
  }

  ${Path}:hover {
    opacity: 0.6;

    &.selected {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 768px) {
    display: block;
  }
`;

const HousePlan = styled.div`
  padding-top: 40px;

  @media(min-width: 1200px) {
    padding-top: 120px;
  }
`;

const Square = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-color: ${({bgColor}) => bgColor};
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  color: #303030;
  margin-bottom: 1rem;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @media(min-width: 576px) {
    flex-direction: row;

    ${LegendItem}:nth-child(2) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  @media(min-width: 1200px) {
    flex-direction: column;

    ${LegendItem}:nth-child(2) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media(min-width: 1400px) {
    flex-direction: row;

    ${LegendItem}:nth-child(2) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
`;

function HouseDetail ({pageContext: { id, floors } }) {
  const [active, setActive] = useState(floors[0].items.filter(i => i.state === 'available').length > 0 ? 0 : 1);

  const { t } = useTranslation();

  const firstFloor = floors[0];
  const secondFloor = floors[1];

  const getFillColor = state => {

    if(state === 'sold') {
      return '#e6e6e6';
    }

    if(state === 'reserved') {
      return '#666666';
    }

    return '#ff940a';
  }

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  return (
    <Layout>
      <Seo title={t('house_detail.title', {id})} />
      <Content>
        <Section>
          <ContentWrapper>
            <LangSelector isDark={true} />
            <Grid>
              <Sidebar>
                <TitleWrapper>
                  <Title as="h1">{t('house_detail.title', {id})}</Title>
                  <Img src={`/data/${id.toLowerCase()}/dum${id}_umisteni.jpg`} alt={t('house_detail.photo_location_alt')} />
                </TitleWrapper>
                <ButtonsWrapper>
                  <Subtitle as="h2">{t('house_detail.subtitle')}</Subtitle>
                  <div>
                  {
                    firstFloor.items.length > 0 && (
                      <Btn isActive={active === 0} onClick={() => setActive(0)}>{t('house_detail.floor_number', {number: 1})}</Btn>
                    )
                  }

                  {
                    secondFloor.items.length > 0 && (
                      <Btn isActive={active === 1} onClick={() => setActive(1)}>{t('house_detail.floor_number', {number: 2})}</Btn>
                    )
                  }
                  <Img src={`/data/${id.toLowerCase()}/dum${id}_umisteni.jpg`} alt={t('house_detail.photo_location_alt')} />
                  <DarkBtn to={APARTMENT_SELECTION}>
                    {t('house_detail.back_btn')}
                  </DarkBtn>
                  </div>
                </ButtonsWrapper>
              </Sidebar>

              <HousePlan>
                {
                  active === 0 && (
                    <Picker>
                      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox={firstFloor.plan.viewbox}>
                        <image href={`/data/${id.toLowerCase()}/pudorys_${id}_${active}.png`}></image>
                          {
                            firstFloor.items.map(({id, building, state, plan}) => (
                              <Link key={id} to={`/dum-${building.toLowerCase()}/${id.toLowerCase()}`}>
                                <g transform={plan?.gTransform}>
                                  <Path d={plan.path} transform={plan.transform} fillColor={getFillColor(state)} />
                                </g>
                              </Link>
                            ))
                          }
                      </svg>
                    </Picker>
                  )
                }

                {
                  active === 1 && (
                    <Picker>
                      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox={secondFloor.plan.viewbox}>
                        <image href={`/data/${id.toLowerCase()}/pudorys_${id}_${active}.png`}></image>
                        {
                            secondFloor.items.map(({id, building, state, plan}) => (
                              <Link key={id} to={`/dum-${building.toLowerCase()}/${id.toLowerCase()}`}>
                                <g transform={plan?.gTransform}>
                                  <Path d={plan.path} transform={plan.transform} fillColor={getFillColor(state)} className={getFillColor(state)} />
                                </g>
                              </Link>
                            ))
                          }
                      </svg>
                    </Picker>
                  )
                }
                <Legend>
                  <LegendItem>
                    <Square bgColor="#ff940a" />
                    <span>{t('state.available')}</span>
                  </LegendItem>
                  <LegendItem>
                    <Square bgColor="#666666" />
                    <span>{t('state.reserved')}</span>
                  </LegendItem>
                  <LegendItem>
                    <Square bgColor="#e6e6e6" />
                    <span>{t('state.sold')}</span>
                  </LegendItem>
                </Legend>
              </HousePlan>
            </Grid>
          </ContentWrapper>
        </Section>
      </Content>
    </Layout>
  )
}

export default HouseDetail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
